.custom-tab .form-group {
    margin-bottom: 0px !important;
}

.custom-tab1 .e-tab-header {
    /*background: #f3f6fb;*/
    background: #fff;
}

/*.custom-tab1.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    color: #666 !important;
}*/

.custom-tab1.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    border: none !important;
    padding-left: 15px;
    padding-right: 0px;
}


.custom-tab1.e-tab .e-tab-header .e-toolbar-item {
    margin-right: 0px;
    height: 40px;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active {
    margin-right: 0px;
    height: 40px;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
    /*background: #f3f6fb !important;*/
    background: #fff !important;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
    /*background: #f3f6fb !important;*/
    background: #fff !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
    color: #009ef7 !important;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    background: #181F49 !important;
    border: 1px solid #181F49 !important;
    top: 100%;
}

.custom-tab1.e-tab .e-toolbar .e-toolbar-item:hover {
    background: none !important;
    border: none !important;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    background: none !important;
    border: none !important;
}

.custom-tab1.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    padding: 2px 10px;
}

/*
.custom-tab1.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    padding: 2px 15px;
    background: #cdd9ee !important;
    border:1px solid #bacbe8;
}*/

.custom-tab1.e-tab .e-items.e-toolbar-items {
    height: 40px;
    display: flex;
    align-items: center;
}

.custom-tab1 .taskAddBtn {
    position: absolute;
    //top: 140px;
    right: 16px;
    z-index: 1002;
}

.custom-tab1 .header-right-div {
    right: 0px;
}

//custom-tab-footer-border style start

.custom-tab-footer-border.e-tab.e-fill .e-tab-header {
    border-bottom-width: 0;
    margin-bottom: 0.75rem;
    margin: 0 0px 0.75rem -10px;
}

.custom-tab-footer-border.e-tab.e-fill.raf-m-0 .e-tab-header {
    margin: 0;
}

.custom-tab-footer-border.e-tab.e-fill.raf-border .e-tab-header {
    border-bottom: 1px solid #ddd;
}

.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
    background-color: transparent;
    border-color: transparent;
    color: #333;
}

.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    border-bottom: 2px solid transparent;
}

.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    border-bottom: 2px solid #0078d6;
}

.custom-viewDropdown-container .custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    border-bottom: 3px solid transparent;
}

.custom-viewDropdown-container .custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    border-bottom: 3px solid #0078d6;
}

.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
    color: #0078d6 !important;
    font-weight: 500;
}

.custom-tab-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
    color: #0078d6 !important;
}

//custom-tab-footer-border  style end

.hideTabIcon.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-icon {
    display: none;
}

.hideTabIcon.e-tab .e-tab-header .e-toolbar-item .e-icon-left+.e-tab-text {
    margin-left: 0px;
    font: var(--body3-medium)
}

//remover-footer-border style start
.e-tab.remover-footer-border.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    border-bottom: 0px;
}

.e-tab.remover-footer-border .e-tab-header .e-toolbar-item.e-active .e-text-wrap .e-text-wrap {
    border-bottom: 0px;
}

.e-tab.remover-footer-border .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    content: unset;
}

//remover-footer-border style end

//custom-tab-footer-border active-bg-gray style start
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header {
    margin: 0;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 2px;
    border: 0;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-items,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    min-height: 38px;
    height: 38px;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill.raf-tab-lg .e-tab-header,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill.raf-tab-lg .e-tab-header .e-toolbar-items,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill.raf-tab-lg .e-tab-header .e-toolbar-item,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill.raf-tab-lg .e-tab-header .e-toolbar-item .e-tab-wrap,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill.raf-tab-lg .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    min-height: 2.75rem;
    height: 2.75rem;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    padding: 0.625rem 1rem;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-icon {
    min-width: unset;
    width: auto;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:focus,
.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:hover {
    background-color: #f4f4f4;
    border-bottom-color: #999;
}

.custom-tab-footer-border.active-bg-gray.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    background-color: #f4f4f4;
}

.custom-tab-footer-border.active-bg-gray.active-bg-white.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:hover {
    background-color: #fff;
    border-bottom: 2px solid #999;
}

.custom-tab-footer-border.active-bg-gray.active-bg-white.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    background-color: #ffff;
    border-bottom: 2px solid #0078d6;
}

//custom-tab-footer-border active-bg-gray style end
//custom-tab-footer-border active-bg-light-blue start
.custom-tab-footer-border.active-bg-gray.active-bg-light-blue.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:hover {
    background-color: #dcecf9;
    border-bottom: 2px solid #999;
}

.custom-tab-footer-border.active-bg-gray.active-bg-light-blue.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    background-color: #dcecf9;
}

//custom-tab-footer-border active-bg-light-blue start
//custom-tab-footer-border active-bg-primary start

.custom-tab-footer-border.active-bg-gray.active-bg-primary.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:hover {
    background-color: #f0f0f0;
    border-bottom: 2px solid transparent;
}

.custom-tab-footer-border.active-bg-gray.active-bg-primary.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap:hover .e-tab-text {
    // color: #fff !important;
}

.custom-tab-footer-border.active-bg-gray.active-bg-primary.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    background-color: #0078d6;
}

.custom-tab-footer-border.active-bg-gray.active-bg-primary.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap .e-tab-text {
    color: #fff !important;
}

//custom-tab-footer-border active-bg-primary end

// custom button tab style start

.e-tab.e-fill.custom-button .e-tab-header .e-toolbar-items .e-hscroll-bar .e-hscroll-content {
    display: flex;
    align-items: center;

}

.e-tab.e-fill.custom-button .e-tab-header {
    display: flex;
    align-items: center;
}

.e-tab.e-fill.custom-button .e-tab-header .e-toolbar-items,
.e-tab.e-fill.custom-button .e-tab-header .e-toolbar-items .e-toolbar-item,
.e-tab.e-fill.custom-button .e-tab-header .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-fill.custom-button .e-tab-header .e-toolbar-items .e-toolbar-item .e-tab-wrap .e-text-wrap {
    height: 32px !important;
    min-height: 32px !important;
    line-height: 32px;
}

.e-tab.e-fill.custom-button {
    height: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    flex-direction: column;

    & .e-tab-header {
        margin-left: 0;
        border-bottom: 0;
        min-height: unset;

        & .e-toolbar-items .e-toolbar-item .e-tab-wrap {
            border-radius: 4px;

            & .e-text-wrap {
                background-color: transparent !important;
                border: unset !important;

                & .e-tab-text {
                    padding-bottom: 1px;
                    font-size: 0.8125rem;
                    font-weight: 500;
                }
            }

        }
    }

    & .withHeight.e-tab-header {
        height: 2.5rem;
        min-height: 2.5rem;
    }
}

// custom button tab style end

// custom button light-primary tab style start

.e-tab.e-fill.custom-button.light-primary .e-tab-header .e-toolbar-items .e-toolbar-item.e-active .e-tab-wrap {
    background-color: #d2e5fc;
    color: #0078d6;
    border-color: transparent;

    & .e-text-wrap {
        background-color: transparent !important;
        border: unset !important;

        & .e-tab-text {
            color: #0078d6;
        }
    }
}

// custom button light-primary tab style end

//box-style show-down-arrow style  start

.box-style.e-tab .e-tab-header {
    margin-left: 0px;
}

.box-style.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    border: 0px;
    padding: 0;
}

.box-style.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    border: 1px solid #666;
    margin: 0;
    padding: 0.25rem 1rem;
    min-height: unset;
    height: auto;
    border-radius: 8px;
}

.box-style.e-tab .e-tab-header .e-toolbar-item .e-text-wrap::before {
    width: 0.5rem;
    height: 0.5rem;
    min-height: 0.5rem;
    min-width: 0.5rem;
    background-color: #f3f4f5;
    border: 0px;
    border-bottom: 1px solid #0078d6;
    border-right: 1px solid #0078d6;
    transform: rotate(45deg);
    position: absolute;
    top: calc(100% - 0.25rem);
    left: calc(50% - 0.25rem);
}

.box-style.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap {
    border: 1px solid #0078d6;
}

.box-style.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.0625rem;
}

.box-style.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-text-wrap .e-tab-text {
    color: #0078d6;
}

//box-style show-down-arrow style  end

// header-tab style start
.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header {
    min-height: auto;
    margin: 0px 0 0 -8px;

}

.viewTitleBtnOutter .custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header {
    margin-top: 2px;
}

.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-item,
.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-items {
    height: auto;
    min-height: auto;
}

.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: auto;
    padding: 0 8px;
}

.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap {
    padding: 0;
    line-height: normal;
    height: auto;

}

.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}


.custom-tab-footer-border.header-tab.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-disable) .e-tab-wrap .e-text-wrap .e-tab-text {
    color: #676a6c;

}

.custom-tab-footer-border.header-tab.e-tab .e-tab-header .e-scroll-nav {
    height: 24px;
    min-height: 24px;

}

// header-tab style end

//oval tab style start
.oval-tab.e-tab.e-fill>.e-tab-header {
    min-height: 32px;
    padding-bottom: 0.5rem;
    border: 0px;

    & .e-toolbar-items {
        min-height: unset;

        & .e-hscroll-bar {
            padding-bottom: 0.5rem;
            margin-bottom: -0.5rem;
        }

        & .e-toolbar-item {
            height: 32px;
            min-height: 32px;

            & .e-tab-wrap {
                background-color: transparent;
                border-radius: 0.5rem;
                border: 1px solid #999;
                height: 32px;
                min-height: 32px;
                position: relative;
                margin-right: 0.5rem;

                &:hover {
                    border-color: #0078d6;

                    & .e-tab-text {
                        color: #0078d6;
                    }
                }
            }

            & .e-text-wrap {
                height: 32px !important;
                min-height: 32px !important;
                padding: 0 10px !important;
                background: transparent !important;
                border: 0px !important;
            }

            &.e-active {
                & .e-tab-wrap {
                    border-color: #0078d6;
                    color: #0078d6;
                    border-radius: 0.5rem;
                    background-color: transparent;

                    &:hover {

                        & .e-tab-text {
                            color: #0078d6;
                        }
                    }

                    &::after {
                        content: "";
                        height: 10px;
                        width: 10px;
                        position: absolute;
                        top: 26px;
                        left: calc(50% - 5px);
                        border-left: 1px solid #1a7bb9;
                        border-bottom: 1px solid #1a7bb9;
                        transform: rotate(-45deg);
                        background: #f4f5f6;
                    }
                }

                & .e-tab-text {
                    color: #0078d6;
                }
            }
        }
    }
}

//oval tab style end

//Full oval tab style start

.full-oval-tab.e-tab>.e-tab-header {
    & .e-toolbar-items {
        margin: 0;

        & .e-toolbar-item {
            min-height: 32px;
            height: 32px;
            margin-right: 0.5rem !important;
            margin-bottom: 0.25rem !important;

            & .e-tab-wrap {
                min-height: 32px;
                height: 32px;
                padding: 0.5rem 0.75rem;
                border-radius: 99px;
                display: flex;
                align-items: center;
                border-color: var(--stroke-neutral-base);

                & .e-text-wrap {
                    min-height: unset;
                    height: unset;

                    & .e-tab-text {
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: var(--content-neutral-base);
                        font-weight: 400;
                    }

                    &::before {
                        content: unset;
                    }
                }
            }

            &.e-active {

                & .e-tab-wrap {
                    background-color: var(--surface-brand-secondary-semi-dark);
                    color: var(--content-neutral-white);
                    border-color: var(--stroke-secondary);
                    // box-shadow: 0px 8px 32px 0px rgba(246, 92, 6, 0.24);

                    & .e-text-wrap {
                        & .e-tab-text {
                            color: var(--content-neutral-white);
                            font-weight: 500;

                        }

                        &::before {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
}


//Full oval tab style end

//active-arrow-bottom tab style start

.active-arrow-bottom.e-tab {
    & .e-tab-header .e-toolbar-item {
        & .e-text-wrap .e-tab-text {
            font-size: 0.875rem;
            font-weight: 500;
        }

        &.e-active .e-text-wrap {
            & .e-tab-text {
                color: #0078d6;
            }

            &::before {
                width: 16px;
                height: 16px;
                background-color: transparent;
                border-left: 1px solid #0078d6;
                border-right: 1px solid #0078d6;
                transform: rotate(45deg);
                left: calc(50% - 8px);
            }
        }
    }
}

//active-arrow-bottom tab style end

//box_bg_blue tab style start
.box_bg_blue.e-tab {
    & .e-tab-header .e-toolbar-item {
        & .e-tab-wrap {
            height: 32px;
            min-height: 32px;
            border-radius: 0.5rem;

            & .e-text-wrap {
                height: 32px;

                & .e-tab-text {
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }
        }

        &.e-active {
            & .e-tab-wrap {
                // background-color: #eef2ff;
                border: 1px solid #6c66e9;

                .e-text-wrap {
                    & .e-tab-text {
                        color: #6c66e9;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

//box_bg_blue tab style end

//customTabDirective style start
.customTabDirective.e-tab.e-fill .e-tab-header {
    border-bottom: 1px solid #dee2e6;
    background-color: #fff;
}

.customTabDirective.e-tab.e-fill>.e-content {
    padding: 0.5rem;
}

.customTabDirective.e-tab .e-toolbar-item .e-tab-wrap {
    border: 0px;
}

.customTabDirective.e-tab .e-toolbar-item.e-toolbar-item.e-active .e-tab-wrap {
    border: 0px;
}

//customTabDirective style end

//customFixed Header Tab Style start
.tab {
    &_fixedHeader_scrollableContent.e-tab.e-fill {
        height: 100% !important;
        display: flex;
        flex-direction: column;

        &>.e-tab-header {
            height: auto !important;
            // overflow: auto;
            // border-bottom: 1px solid #d3d7de;
        }

        &>.e-content {
            height: 100% !important;
            overflow-y: auto;
            overflow-x: hidden;
            border-top: 0px;

            & .e-item.e-active {
                height: 100%;

                &>div {
                    height: 100%;

                    &>div {
                        height: 100%;
                    }
                }
            }
        }

        &.with-content-padding {
            &>.e-content {
                padding: 1rem;
            }
        }

        &.with-content-padding {
            &>.e-content {
                padding: 1rem;
            }
        }
    }

    &_page_container {
        &>.e-content {
            width: 100%;
            max-width: 29cm;
            margin: auto;
        }
    }

    &_content_card {
        &>.e-content {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}

.customTabDirective.tab {
    &_fixedHeader_scrollableContent.e-tab.e-fill {

        &>.e-content {
            padding: 0;
        }

        &>.e-content .e-item,
        &>.e-content .e-item>div,
        &>.e-content .e-item>div>div {
            height: 100%;

            & .raf-content {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}

//customFixed Header Tab Style end

//Tab header bg white start

.e-tab.header-bg-white .e-tab-header {
    background-color: #fff;

    & .e-toolbar-items {
        padding: 0 0.25rem;
    }
}

//Tab header bg white end

//customFixed Footer Tab Style start
.footer-tab.e-tab {
    background-color: #fff;
    padding: 0.375rem 0;
    box-shadow: 0 -4px 8px 4px rgba(0, 0, 0, 0.1);
    // background-color: #eaeaea;

    & .e-tab-header {
        & .e-toolbar-items {
            justify-content: space-around;

            & .e-tab-wrap {
                border: 0px;

                & .e-text-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &::before {
                        content: none;
                    }

                    & .e-tab-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::before {
                            font-size: 1rem;
                        }
                    }

                    & .e-tab-text {
                        font-size: 0.75rem;
                        font-weight: 500;
                        margin-left: 0px;
                    }
                }
            }

            & .e-toolbar-item.e-active {

                & .e-text-wrap {
                    & .e-tab-icon {
                        color: #0078d6;
                    }

                    & .e-tab-text {
                        color: #0078d6;

                    }
                }
            }
        }
    }
}

.hide-first-item.e-tab {
    & .e-tab-header {
        & .e-toolbar-items {
            & .e-indicator {
                &~.e-toolbar-item {
                    display: none;

                    &~.e-toolbar-item {
                        display: inline-flex;
                    }
                }
            }
        }
    }
}

//customFixed Footer Tab Style end

.footer_tab {
    &_items {
        display: flex;
        justify-content: space-around;
        // position: fixed;
        // bottom: 0;
        width: 100%;
        background-color: #f3f3f3;
        padding: 0.375rem 0 1rem;
        list-style: none;
        margin: 0;
        box-shadow: 0.125rem -0.25rem 0.5rem rgba(0, 0, 0, 0.2);
        // z-index: 100;
        position: relative;
    }

    &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: var(--text-block-grey-color);


        &_icon {
            font-size: 20px;
        }

        &.active {
            color: var(--bs-primary);
        }
    }
}

//dialog header and content style changes for dialog header with tab ---------> style start
.e-dlg-container {
    & .e-dialog {
        & .tab_fixedHeader_scrollableContent {
            &_dialog {
                &_header.e-dlg-headerContent {
                    // border-bottom: 0px !important;
                    padding: 0.75rem 1rem 0.75rem 1rem !important;
                }
            }

            &.e-tab.e-fill {
                & .e-tab-header .e-toolbar-items {
                    padding-left: 0.25rem;
                    border-bottom: 1px solid #dee2e6;
                    // margin-top: -0.125rem;
                }
            }
        }
    }
}


//dialog header and content style changes for dialog header with tab ---------> style end

//Tab Header style start

.e-tab.padding_tabHeader {
    &>.e-tab-header {
        padding: 1rem;
        box-sizing: border-box;
    }

    &_x>.e-tab-header .e-toolbar-items {
        padding: 0 1rem;
        box-sizing: border-box;
    }

    &.custom-tab-footer-border>.e-tab-header {
        padding: 0 0 0 1rem;
        box-sizing: border-box;
    }
}

.e-tab.padding_tabHeader_sm {
    &>.e-tab-header .e-toolbar-items:not(.e-hscroll) {
        padding-left: 0.5rem;
    }
}

.e-bigger .e-tab.tabHeader_secondaryHeader {
    &>.e-tab-header .e-toolbar-items {
        padding-left: 1rem;
    }
}

.e-tab.e-fill.transparent_tabHeader .e-tab-header {
    background-color: transparent;
}

.e-tab.e-fill.white_tabHeader .e-tab-header {
    background-color: #fff;
}

.e-tab.e-fill.grey_tabHeader .e-tab-header {
    background-color: var(--surface-neutral-base);
}

.e-tab.e-fill.fixedHeight_tabHeader {
    &>.e-tab-header {

        height: 3rem !important;
        min-height: 3rem !important;
        display: flex;
        align-items: center;

        &.raf-md {
            height: 2.5rem !important;
            min-height: 2.5rem !important;
        }

        & .e-toolbar-items {
            padding: 0 1rem;
            display: flex !important;
            align-items: center;
        }
    }
}

.e-tab.e-fill.fixedHeight_tabHeader.hide-tab-menu {
    &>.e-tab-header {
        display: none;

    }
}

.e-tab.e-fill.borderBottom_tabHeader {
    &>.e-tab-header {
        border-bottom: 1px solid #dee2e6;
    }
}

//Tab Header style end

.sticky__tabHeader>.e-tab-header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

//raf_tab_header style started
.raf_tab_header {
    height: 2.25rem;
    min-height: 2.25rem;
    display: flex;
    align-items: center;
    overflow: auto;
    width: 100%;

    &_container {
        background-color: #fff;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        overflow: auto;
        width: 100%;
    }

    &_item {
        padding: 0 1rem;
        height: 100%;
        min-height: 100%;
        // border-bottom: 2px solid transparent;
        white-space: nowrap;
        font: var(--body3-medium);
        color: var(--content-neutral-dark);
        display: flex;
        align-items: center;
        // font-weight: 400;
        cursor: pointer;
        position: relative;

        &.active {
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 2px solid var(--stroke-primary);
            }

            color: var(--content-brand-primary-base);
            font-weight: 600;
        }
    }

    &.raf_normal {
        height: 2.25rem;
        min-height: 2.25rem;
    }

    &.raf_lg {
        height: 2.75rem;
        min-height: 2.75rem;
        
        & .raf_tab_header_item {
            font: var(--body2-medium)
        }
    }

    &.raf_md {
        height: 32px;
        min-height: 32px;
    }

    &_shadow {
        box-shadow: 6px 0px 5px -5px rgba(0, 0, 0, 0.3);
    }

    &.full-oval-tab {
        & .raf_tab_header_item {
            border-radius: 99px;
            border: 1px solid var(--stroke-neutral-base);

            &:not(:first-child) {
                margin-left: 0.5rem;
            }

            &.active {
                background-color: var(--surface-brand-secondary-semi-dark);
                color: var(--content-neutral-white);
                border-color: var(--stroke-secondary);
                font-weight: 600;

                &::before {
                    content: none;
                }
            }
        }
    }

    &.box-tab {
        & .raf_tab_header_item {
            border-radius: 0.5rem;
            border: 1px solid var(--stroke-neutral-base);
            color: var(--content-neutral-base);

            &:not(:first-child) {
                margin-left: 0.5rem;
            }

            &.active {
                background-color: var(--surface-brand-secondary-semi-dark);
                color: var(--content-neutral-white);
                border-color: var(--stroke-secondary);
                font-weight: 600;

                &::before {
                    content: none;
                }
            }
        }
    }

    &.light_primary_tab {
        & .raf_tab_header_item {
            &.active {
                background-color: var(--surface-brand-primary-light);
                color: var(--content-brand-primary-base);
                border-color: var(--stroke-primary);
                font-weight: 600;

                &::before {
                    content: none;
                }
            }
        }

    }

    &.secondary_tab {
        .raf_tab_header_item.active {
            color: var(--content-brand-secondary-base);

            &::before {
                border-bottom-color: var(--stroke-secondary);
            }
        }
    }

    &.full_width_tab {
        & .raf_tab_header_item {
            flex: 1;
            text-align: center;
            justify-content: center;
        }
    }

    &.flex-wrap {
        flex-wrap: wrap;
        height: auto;

        .raf_tab_header {
            &_item {
                height: 2.25rem;
                min-height: 2.25rem;

                &.raf_md {
                    height: 2rem;
                    min-height: 2rem;
                }
            }
        }
    }
}

//raf_tab_header style end

@media only screen and (min-width: 576px) {

    .customTabDirective.e-tab.e-fill>.e-content {
        padding: 1rem;
    }

}