//custom-treeview style start
.custom-treeview.e-treeview {
    &.fixedTemplate {
        & .e-text-content {
            & .e-list-icon {
                margin-left: 0;
                display: none;
            }
        }
    }

    & .e-ul {
        padding: 0 0 0 0.625rem;
        overflow: hidden;
        margin: 0;

        & .e-list-item {
            padding: unset;

            &>.e-fullrow {
                height: 48px;
            }

            &>.e-text-content {
                height: 48px;

                .e-list-text {
                    padding: 0.5rem 0.5rem 0.5rem 0;
                }
            }

        }
    }
}

//custom-treeview style end

//List treeview style start
.listPage-treeview.e-treeview {
    & .e-ul {

        & .e-list-item {
            background-color: #fff;

            &>.e-fullrow {
                height: 60px;
                border-bottom: 1px solid #e0e0e0;
                background-color: #fff;
            }

            &>.e-text-content {
                height: 60px;
                display: flex;
                align-items: center;

                .e-list-text {
                    padding: 0.25rem 1rem 0.25rem 0;
                    width: 100%;
                }

                & .hover-show-child-div {
                    visibility: hidden;
                }
            }

            &.e-hover {
                &>.e-fullrow {
                    background-color: #f4f4f4;
                }

                &>.e-text-content {
                    & .hover-show-child-div {
                        visibility: visible;
                    }
                }
            }

            &.e-active {

                &.e-hover,
                &.e-node-focus {
                    &>.e-fullrow {
                        border: 1px solid #999;
                    }
                }
            }


        }
    }
}

//List treeview style end

//Show Button on hover in tree view component start
.e-treeview {
    & .e-list-item {
        & .e-text-content {
            & .hover-child-div {
                display: none;
            }

            & .visible-child-div {
                visibility: hidden;
            }
        }

    }

    & .e-list-item.e-hover {
        &>.e-text-content {
            & .hover-child-div {
                display: block;
            }

            & .visible-child-div {
                visibility: visible;
            }
        }

    }

}

//Show Button on hover in tree view component end